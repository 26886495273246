// @ts-ignore
import { Box, useBreakpointValue } from '@chakra-ui/react';
import cookies from 'js-cookies';
import PageMetatags from 'layout/PageMetatags';
import { merge } from 'lodash';
import Lottie from 'lottie-react';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import alexThumbsUp from '../../../assets/animations/alex-thumbs-up.json';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import PageHeading from '../../../components/PageHeading';
import config from '../../../config';
import { SupportedCountriesVatFormatting } from '../../../helpers/localization';
import { useStep } from '../../../helpers/useStep';
import useConfigQuery from '../../../hooks/queries/useConfigQuery';
import useDistributorQuery from '../../../hooks/queries/useDistributorQuery';
import useWorkshopPendingQuery from '../../../hooks/queries/workshop/useWorkshopPendingQuery';
import { WorkshopData } from '../../../hooks/queries/workshop/useWorkshopQuery';
import { PublicPageLayout } from '../../../layout/PublicPageLayout';
import { Stepper } from '../../serviceRecord/Stepper';
import EmailVerificationStep from './EmailVerificationStep';
import SetPasswordStep from './SetPasswordStep';
import SignupStep from './SignupStep';
import SignupStepSplitted from './SignupStepSplitted';

const RegistrationPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let { workshop_id, registration_key } = useParams();
  const workshopId = Number(workshop_id);
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code') || undefined;
  const setCode = urlParams.get('setCode') || undefined;
  const setPassword = urlParams.get('setPassword') || undefined;
  const posthog = usePostHog();

  const queryParams = new URLSearchParams(location.search);
  let did = queryParams.get('did');

  const configQuery = useConfigQuery();
  const configQueryData = configQuery.data?.data;

  const countries = configQueryData?.countries || [];
  const languages = configQueryData?.languages || {};
  const telephone_prefixes = configQueryData?.telephone_prefixes || {};
  const zipcode_length = configQueryData?.countries || {};

  const [currentStep, { setStep }] = useStep({ maxStep: 2, initialStep: 0 });
  const [wizardFormData, setWizardFormData] = useState<Partial<WorkshopData>>({});
  const [userEmail, setUserEmail] = useState<string>('');
  const [expandForm, setExpandForm] = useState(false);

  // const workshopPendingQuery = useWorkshopPendingQuery(workshopId, registration_key);
  // const workshopPendingData = workshopPendingQuery.data?.data;

  const distributorId = Number(did);
  const distributorQuery = useDistributorQuery(distributorId);
  const distributorData = distributorQuery.data?.data;

  const pagepathIncludesSignup = location.pathname.includes('signup');
  const abTestNewDesign =
    (posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' || posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign') &&
    pagepathIncludesSignup;

  const abTestSignupFormSplit = posthog.getFeatureFlag('signupFormTrust') === 'SignupFormSplit';
  if (did === null) {
    //check cookie value
    const cookieValue = cookies.getItem('oe_did');
    if (cookieValue) {
      did = cookieValue;
    }
  }

  useEffect(() => {
    let shouldNavigate = false;
    let navigateUrl = `/signup/${wizardFormData.id}/${wizardFormData.registration_key}?`;

    if (wizardFormData?.email_verified) {
      navigateUrl = navigateUrl + `setPassword=true`;
      shouldNavigate = true;
    } else if (wizardFormData?.id) {
      shouldNavigate = true;
      navigateUrl = navigateUrl + `setCode=true`;
    }

    if (did) {
      navigateUrl = navigateUrl + `&did=${did}`;
    }

    if (shouldNavigate) {
      navigate(navigateUrl);
    }
  }, [currentStep]);

  useEffect(() => {
    setStep(0);
    setWizardFormData({
      registration_key,
      distributor_id: did,
    });
    if (code) {
      setStep(2);
    }
    if (setCode) {
      setStep(1);
    } else if (setPassword) {
      setStep(2);
    }
  }, [workshop_id]);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const steps = [
    {
      title: 1,
      stepHeader: !abTestSignupFormSplit
        ? t('workshop:registration.stepper.one.header')
        : abTestSignupFormSplit && !expandForm
          ? t('workshop:registration.stepper.one.header_part_1')
          : t('workshop:registration.stepper.one.header_part_2'),

      stepDescription: !abTestSignupFormSplit
        ? t('workshop:registration.stepper.one.description')
        : abTestSignupFormSplit && !expandForm
          ? t('workshop:registration.stepper.one.subheader_part_1')
          : t('workshop:registration.stepper.one.subheader_part_2'),
      content: abTestSignupFormSplit ? (
        <SignupStepSplitted
          expandForm={expandForm}
          setExpandForm={setExpandForm}
          setUserEmail={setUserEmail}
          saveInState={(stateData) => setWizardFormData(merge(wizardFormData, stateData))}
          data={wizardFormData}
          setStep={setStep}
          method={workshop_id ? 'put' : 'post'}
          url={workshop_id ? `${config.apiBaseUrl}/workshop/${workshop_id}/update-registration` : `${config.apiBaseUrl}/signup`}
          localizationProps={{
            countries,
            languages,
            zipcodeLength: zipcode_length,
            vatFormatting: SupportedCountriesVatFormatting,
            telephonePrefixes: telephone_prefixes,
          }}
        />
      ) : (
        <SignupStep
          setUserEmail={setUserEmail}
          saveInState={(stateData) => setWizardFormData(merge(wizardFormData, stateData))}
          data={wizardFormData}
          setStep={setStep}
          method={workshop_id ? 'put' : 'post'}
          url={workshop_id ? `${config.apiBaseUrl}/workshop/${workshop_id}/update-registration` : `${config.apiBaseUrl}/signup`}
          localizationProps={{
            countries,
            languages,
            zipcodeLength: zipcode_length,
            vatFormatting: SupportedCountriesVatFormatting,
            telephonePrefixes: telephone_prefixes,
          }}
        />
      ),
    },
    {
      title: 2,
      stepHeader: t('workshop:registration.stepper.two.header'),
      stepDescription: t('workshop:registration.stepper.two.description'),
      content: (
        <EmailVerificationStep
          saveInState={(stateData) => {
            setWizardFormData(merge(wizardFormData, stateData));
          }}
          registrationKey={registration_key}
          data={{ ...wizardFormData, workshop_id, code }}
          setStep={setStep}
          userEmail={userEmail}
        />
      ),
    },
    {
      title: 3,
      stepHeader: t('workshop:registration.stepper.three.header'),
      stepDescription: t('workshop:registration.stepper.three.description'),
      content: <SetPasswordStep data={wizardFormData} workshopId={workshopId} />,
    },
  ];

  if (distributorQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Box bgColor={abTestNewDesign ? 'gray.200' : undefined} position={'relative'} minHeight={'100vh'}>
      <PublicPageLayout
        abTestNewDesign={abTestNewDesign}
        showOELogo={!distributorData?.prepaid_plan}
        distributor={distributorData}
        showCobrandedLogo={!!distributorData?.prepaid_plan}
        showCobrandedSubheadline={!!distributorData?.prepaid_plan && currentStep === 0}
        backgroundColor={abTestNewDesign && currentStep === 0 ? 'gray.200' : undefined}
        currentStep={currentStep}
      >
        <PageMetatags title={t('workshop:registration.label')}>
          <script src="https://js.chargebee.com/v2/chargebee.js"></script>
        </PageMetatags>
        {!abTestSignupFormSplit && <PageHeading isLoading={configQuery.isFetching} title={t('workshop:registration.label')} />}

        <Stepper
          abTestNewDesign={abTestNewDesign}
          isLoading={configQuery.isFetching}
          steps={steps}
          currentStep={currentStep}
          content={steps[currentStep].content}
        />
      </PublicPageLayout>
      {abTestNewDesign && !isMobile && (
        <Box position={'fixed'} left={0} bottom={0} w={'20%'} h={'auto'}>
          <Lottie animationData={alexThumbsUp} loop={true} />
        </Box>
      )}
    </Box>
  );
};

export default RegistrationPage;
