import { Box, Button, Center, Container, Grid, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import GetInTouchCard from 'components/GetInTouchCard';
import PageMetatags from 'layout/PageMetatags';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import { get, isEmpty, isNil, minBy } from 'lodash';
import Lottie from 'lottie-react';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { BILLING_CYCLE, CHARGE_KEYS, LooseObject, SubscriptionPlan } from '../../../../api/types';
import alexThumbsUp from '../../../../assets/animations/alex-thumbs-up.json';
import CustomerLogoBar from '../../../../components/CustomerLogoBar/CustomerLogoBar';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import PageHeading from '../../../../components/PageHeading';
import { PromotionBanner } from '../../../../components/PromotionBanner';
import PlanSlider from '../../../../components/Slider/Slider';
import TestimonialsOverViewCard from '../../../../components/Testimonials/TestimonialsOverViewCard';
import { testimonials } from '../../../../configs/testimonials';
import { useUserContext } from '../../../../context/UserContextProvider';
import { formatMultiCurrencyIntl } from '../../../../helpers/formatCurrency';
import usePlansQuery from '../../../../hooks/queries/usePlansQuery';
import usePromotionsQuery from '../../../../hooks/queries/usePromotionsQuery';
import { AvailablePlansCard } from './AvailablePlansCard';
export interface ISubscriptionForm {
  plan_id: number;
  billing_cycle: BILLING_CYCLE;
}
const schema = yup
  .object({
    plan_id: yup.number().required(),
    billing_cycle: yup.string().required(),
  })
  .required();

export const SelectSubscriptionPlanPage = () => {
  const posthog = usePostHog();
  const [sendPostHogEvent, setSendPostHogEvent] = useState(true);
  const userContext = useUserContext();
  const currency = userContext.workshop?.currency;
  const workshopLanguage = userContext.workshop?.language;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const plansQuery = usePlansQuery();
  const plansData = plansQuery.data?.data || [];

  const promotionsQuery = usePromotionsQuery();
  const promotionsData = promotionsQuery.data?.data || [];

  const isNoSetupFee = promotionsData?.some((promotion: any) => promotion?.remove_setup_fee === true) ?? false;

  const [numberOfVehicles, setNumberOfVehicles] = useState(5);
  const [savings, setSavings] = useState(0);
  const [recommendedPlanId, setRecommendedPlanId] = useState(0);
  const [paymentDisabled, setPaymentDisabled] = useState(true);

  let { search } = useLocation();
  const query = new URLSearchParams(search);

  const paramBillingCycle = query.get('billingCycle');
  const paramPlanId = query.get('planId');

  const { handleSubmit, watch, setValue } = useForm<ISubscriptionForm>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      billing_cycle: paramBillingCycle ? paramBillingCycle : BILLING_CYCLE.MONTHLY,
    },
  });
  const planId = watch('plan_id');
  const selectedPlan = plansData.find((plan) => plan?.id === planId) as SubscriptionPlan;
  const billingCycle = watch('billing_cycle');
  const onSubmit: SubmitHandler<ISubscriptionForm> = (data) => {
    navigate(`/app/setup/subscription-summary/${data?.plan_id}/${data?.billing_cycle}`);
  };

  // useEffect(() => {
  //   if (plansQuery.isFetched) {
  //     let calculatedCosts: LooseObject[] = [];
  //     // @ts-ignore
  //     plans.forEach((plan: SubscriptionPlan) => {
  //       const totalSrQuota = numberOfVehicles - plan.monthly_sr_quota;
  //       const totalShrQuota = numberOfVehicles - plan.monthly_shr_quota;
  //       const multiplayer = billingCycle === BILLING_CYCLE.YEARLY ? 12 : 1;
  //
  //       const totalCostPerPlan =
  //         plan?.price[billingCycle]?.amount +
  //         (totalSrQuota > 0
  //           ? totalSrQuota *
  //             plan?.charges[CHARGE_KEYS.SERVICE_RECORD]?.price *
  //             multiplayer
  //           : 0) +
  //         (totalShrQuota > 0
  //           ? totalShrQuota *
  //             plan?.charges[CHARGE_KEYS.SERVICE_HISTORY_REQUEST]?.price *
  //             multiplayer
  //           : 0);
  //       //check outside of the loop is recommend plan is not ppu
  //       calculatedCosts.push({
  //         id: plan?.id,
  //         price: totalCostPerPlan,
  //       });
  //       const totalPpuPrice = calculatedCosts[0];
  //       const recommendedPlanAccordingToPrice = minBy(calculatedCosts, 'price');
  //       setRecommendedPlanId(recommendedPlanAccordingToPrice?.id);
  //       setValue('plan_id', paramPlanId ? parseInt(paramPlanId) : null);
  //       if (recommendedPlanAccordingToPrice?.id !== totalPpuPrice?.id) {
  //         setSavings(
  //           get(totalPpuPrice, 'price', 0) -
  //             get(recommendedPlanAccordingToPrice, 'price', 0),
  //         );
  //       } else {
  //         setSavings(0);
  //       }
  //     });
  //   }
  // }, [numberOfVehicles, plansQuery.isFetched, billingCycle]);

  useEffect(() => {
    if (selectedPlan?.is_pay_per_use) {
      setValue('billing_cycle', BILLING_CYCLE.MONTHLY);
    }
  }, [selectedPlan]);

  // useEffect(() => {
  //   if (paramPlanId) {
  //     setPaymentDisabled(false);
  //   }
  // }, []);

  const breakpointValue = useBreakpointValue({ base: 'sm', lg: 'lg' });
  const abTestNewDesign =
    posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' || posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign';

  const isMobile = useBreakpointValue({ base: true, md: false });

  const boxWidth = useBreakpointValue({
    base: '100%',
    sm: '90%',
    md: '60%',
    lg: '65%',
    xl: '65%',
    '2xl': '80%',
  });
  if (sendPostHogEvent) {
    posthog.capture('select_subscription_page_pageview');
    setSendPostHogEvent(false);
  }

  if (promotionsQuery.isLoading || plansQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <PublicPageLayout abTestNewDesign={abTestNewDesign}>
      <PageMetatags title={t('pages:subscription.meta.title')}></PageMetatags>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box position={'relative'} width={abTestNewDesign ? boxWidth : 'full'}>
          <Container
            bgColor={abTestNewDesign ? 'gray.200' : undefined}
            mt={abTestNewDesign ? '10' : '0'}
            as={'form'}
            onSubmit={handleSubmit(onSubmit)}
            data-test-id="subscription-plan-card"
          >
            <Stack spacing={4} pt={4} pb={{ base: 4, lg: 10 }} textAlign={'center'} style={{ userSelect: 'none' }}>
              <PageHeading title={t('pages:subscription.personal.title')} textAlign={'center'} size={breakpointValue} />
              <Text fontSize={'sm'}>{t('pages:subscription.personal.info')}</Text>
            </Stack>
            {promotionsData.map((promotion, index) => {
              if (promotion?.location?.includes('create_subscription')) {
                return <PromotionBanner key={index} promotion={promotion} />;
              }
              return null;
            })}
            <Center>
              <Grid gap={{ base: 6, lg: 10 }}>
                {/* <PlanSlider
              value={numberOfVehicles}
              onChange={setNumberOfVehicles}
              workshopLanguage={workshopLanguage}
            /> */}
                <CustomerLogoBar />
                <AvailablePlansCard
                  abTestNewDesign={abTestNewDesign}
                  currency={currency}
                  isCreatePage
                  billingCycle={billingCycle}
                  recommendedPlanId={recommendedPlanId}
                  plans={plansData}
                  savings={savings}
                  isNoSetupFee={isNoSetupFee}
                  isLoading={
                    plansQuery.isLoading || isNil(selectedPlan) || (isNil(selectedPlan?.id) && plansData.length > 0) || promotionsQuery.isLoading
                  }
                  temporaryPromotionGuard={!isEmpty(promotionsQuery.data)}
                  onChangeInterval={(val) => setValue('billing_cycle', val)}
                  selectedPlan={selectedPlan}
                  onChangeActivePlan={(slug: string) => {
                    setPaymentDisabled(false);
                    //@ts-ignore
                    const newActivePlan: SubscriptionPlan = plansData.find((plan: SubscriptionPlan) => plan.slug === slug);
                    setValue('plan_id', newActivePlan.id);
                  }}
                  workshopLegacy={userContext.workshop?.is_legacy}
                />

                {userContext.workshop?.is_legacy ? (
                  <Box w="full" borderRadius="lg" p={4} bgColor="green.100" zIndex={1000}>
                    {t('pages:subscription.credit.text', {
                      credit: formatMultiCurrencyIntl(userContext.workshop?.wp_wallet_balance, currency),
                    })}
                  </Box>
                ) : null}
                <Stack>
                  <Button
                    type="submit"
                    variant="primary"
                    size="sm"
                    color="on-accent-muted"
                    width={'200px'}
                    padding={'20px'}
                    alignSelf={'center'}
                    disabled={paymentDisabled}
                  >
                    {t(`pages:subscription.checkout.action`)}
                  </Button>
                </Stack>
              </Grid>
            </Center>
            <TestimonialsOverViewCard testimonials={testimonials} abTestNewDesign={abTestNewDesign} />
            <GetInTouchCard abTestNewDesign={abTestNewDesign} />
          </Container>
        </Box>
        {abTestNewDesign && !isMobile && (
          <Box position={'fixed'} left={0} bottom={0} w={'15%'} h={'auto'}>
            <Lottie animationData={alexThumbsUp} loop={true} />
          </Box>
        )}
      </Box>
    </PublicPageLayout>
  );
};
// @ts-ignore
export default SelectSubscriptionPlanPage;
