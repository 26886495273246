import { Box, useBreakpointValue, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { intersection, merge } from 'lodash';
import Lottie from 'lottie-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import alexThumbsUp from '../../../assets/animations/alex-thumbs-up.json';
import config from '../../../config';
import { WORKSHOP_ACTIVE } from '../../../context/UserContextProvider';
import { SupportedCountriesVatFormatting } from '../../../helpers/localization';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import { useStep } from '../../../helpers/useStep';
import usePreregistratedWorkshopQuery from '../../../hooks/queries/spf/usePreregistratedWorkshopQuery';
import useSpfConfigQuery from '../../../hooks/queries/spf/useSpfConfigQuery';
import useSPFFileQuery from '../../../hooks/queries/spf/useSPFFileQuery';
import { WorkshopData } from '../../../hooks/queries/workshop/useWorkshopQuery';
import { PublicPageLayout } from '../../../layout/PublicPageLayout';
import { Stepper } from '../../serviceRecord/Stepper';
import { IAccountVerificationForm } from '../verification/VerificationForm';
import SignupStep, { SignupStepKeys } from './SignupStep';
import VerificationStep from './VerificationStep';

const PreregistrationPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  let { registration_key } = useParams();
  const [errors, setError] = useState({});
  const [currentStep, { setStep }] = useStep({ maxStep: 1, initialStep: 0 });
  const [wizardFormData, setWizardFormData] = useState<Partial<WorkshopData>>({});
  const [stopLoading, setStopLoading] = useState(false);

  const spfConfigQuery = useSpfConfigQuery();
  const file = useSPFFileQuery(`/workshop/${registration_key}/power-of-attorney`);
  const urlParams = new URLSearchParams(window.location.search);
  const redirectUrl = urlParams.get('redirectUrl') || undefined;

  const preregistratedWorkshopQuery = usePreregistratedWorkshopQuery(registration_key);
  const showCobrandedLogo = preregistratedWorkshopQuery?.data?.systempartner?.logo_url;
  const showCobrandedSubheadline = preregistratedWorkshopQuery?.data?.systempartner?.name;

  useEffect(() => {
    setWizardFormData({
      ...preregistratedWorkshopQuery?.data,
      consent_termsandconditions: false,
      consent_dataprivacy: false,
      registration_key,
    });
    if (preregistratedWorkshopQuery.data?.status === WORKSHOP_ACTIVE) {
      if (preregistratedWorkshopQuery.data?.is_verified) {
        navigate('/app/w/dashboard');
      } else {
        setStep(1);
      }
    }
  }, [preregistratedWorkshopQuery.isFetched]);

  const { mutate, isLoading } = useMutation<any, Error, IAccountVerificationForm>(async (data) => {
    const formData = new FormData();
    formData.append('business_licence', data.business_licence.file as File);
    formData.append('identification', data.identification.file as File);
    formData.append('power_of_attorney', data.power_of_attorney.file as File);

    //append all data from step 1
    SignupStepKeys.forEach((field: string) => {
      if (['consent_termsandconditions', 'consent_dataprivacy'].includes(field)) {
        // @ts-ignore
        formData.append(field, Number(wizardFormData[field]));
      } else {
        // @ts-ignore
        formData.append(field, wizardFormData[field]);
      }
    });

    return await axios.post(`${config.spfApiBaseUrl}/workshop/${registration_key}`, formData).catch((err) => {
      manageErrorResponse({ toastInstance: toast, error: err.response, t });
      return Promise.reject(err);
    });
  });
  const onSubmit: SubmitHandler<IAccountVerificationForm> = (formData) => {
    setStopLoading(false);
    mutate(formData, {
      onSuccess: () => {
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          navigate('/complete-registration/done');
        }
      },
      onError: (error: any) => {
        const { errors } = error.response.data;
        setStopLoading(true);

        (Object.keys(errors) as Array<keyof IAccountVerificationForm>).forEach((key) => {
          setError({
            [key]: {
              type: 'server',
              message: errors[key]!.join('. '),
            },
          });
        });

        //check error in first step
        const overlapStep1 = intersection(Object.keys(errors), SignupStepKeys);

        //if first step
        if (overlapStep1.length > 0) {
          setStep(0);
          return;
        }
      },
    });
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const steps = [
    {
      title: 1,
      stepHeader: t('workshop:preregistration.stepper.one.header'),
      stepDescription: t('workshop:preregistration.stepper.one.description'),
      content: (
        <SignupStep
          saveInState={(stateData) => setWizardFormData(merge(wizardFormData, stateData))}
          data={wizardFormData}
          setStep={setStep}
          backendErrors={errors}
          isLoadingParent={preregistratedWorkshopQuery.isFetching}
          localizationProps={{
            countries: spfConfigQuery.data?.countries,
            languages: spfConfigQuery.data?.languages,
            zipcodeLength: spfConfigQuery.data?.zipcode_length,
            vatFormatting: SupportedCountriesVatFormatting,
            telephonePrefixes: spfConfigQuery.data?.telephone_prefixes,
          }}
        />
      ),
    },
    {
      title: 2,
      stepHeader: t('workshop:preregistration.stepper.two.header'),
      stepDescription: t('workshop:preregistration.stepper.two.description'),
      content: (
        <VerificationStep isLoading={isLoading && !stopLoading} onSubmit={onSubmit} workshop={wizardFormData} file={file} backendErrors={errors} />
      ),
    },
  ];

  if (preregistratedWorkshopQuery.isFetching || spfConfigQuery.isFetching || preregistratedWorkshopQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box bgColor={'gray.200'} minHeight={'100vh'}>
      <PublicPageLayout
        systemPartner={preregistratedWorkshopQuery.data?.systempartner}
        overWriteDesign={true}
        //showCobrandedLogo={showCobrandedLogo}
        //showCobrandedSubheadline={showCobrandedSubheadline}
      >
        <Stepper
          overWriteDesign={true}
          isLoading={preregistratedWorkshopQuery.isFetching || spfConfigQuery.isFetching}
          steps={steps}
          currentStep={currentStep}
          content={steps[currentStep].content}
        />
      </PublicPageLayout>
      {currentStep === 0 && !isMobile && (
        <Box position={'absolute'} left={0} bottom={0} w={'20%'} h={'auto'}>
          <Lottie animationData={alexThumbsUp} loop={true} />
        </Box>
      )}
    </Box>
  );
};

export default PreregistrationPage;
