import { Box, Button, Flex, Heading, Icon, Text, useBreakpointValue, useToken } from '@chakra-ui/react';
import { ContentCard } from 'layout/ContentCard';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import GetInTouchCard from '../components/GetInTouchCard';

const ServiceProcessExplainedPage = () => {
  const { t } = useTranslation();

  const NumberIcon = ({ number, background = 'blue.500', color = 'white' }: { number: number | string; background?: string; color?: string }) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      width="60px"
      height="60px"
      backgroundColor={background}
      color={color}
      fontWeight="bold"
    >
      <Text>{number}</Text>
    </Box>
  );

  return (
    <>
      <ContentCard
        minH={'unset'}
        icon={<Icon as={AiFillQuestionCircle} color="accent" boxSize="12" m={1} />}
        header={t('service_process_explained:header')}
        contentDescription={t('service_process_explained:title')}
      >
        <section className="vertical-timeline__wrapper" style={{ marginLeft: -55 }}>
          <Box maxWidth={1183}>
            <Text mt={1} mb={3} fontSize="sm">
              {' '}
              {t('service_process_explained:general_description.one')}{' '}
            </Text>
            <Text mt={1} mb={3} fontSize="sm">
              {t('service_process_explained:general_description.two')}{' '}
            </Text>
            <Text mt={1} mb={6} fontSize="sm">
              {t('service_process_explained:general_description.three')}{' '}
            </Text>
          </Box>
          <VerticalTimeline lineColor={useToken('colors', 'blue.500')} layout={'2-columns'}>
            <VerticalTimelineElement icon={<NumberIcon number={1} background="gray.500" />} position="left">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>{t('service_process_explained:schedule_appointment.title')}</Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:schedule_appointment.description_one')}</Text>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:schedule_appointment.description_two')}</Text>
            </VerticalTimelineElement>
            <VerticalTimelineElement icon={<NumberIcon number={2} />} position="right">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>{t('service_process_explained:identify_vehicle.title')}</Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:identify_vehicle.description_one')}</Text>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:identify_vehicle.description_two')}</Text>
              <Text style={{ fontSize: 14 }}>
                <Trans i18nKey="service_process_explained:identify_vehicle.description_three" />
              </Text>
            </VerticalTimelineElement>
            <VerticalTimelineElement icon={<NumberIcon number={3} />} position="right">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>{t('service_process_explained:analyze_service_history.title')}</Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:analyze_service_history.description_one')}</Text>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:analyze_service_history.description_two')}</Text>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:analyze_service_history.description_three')}</Text>
            </VerticalTimelineElement>
            <VerticalTimelineElement icon={<NumberIcon number={4} />} position="right">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>{t('service_process_explained:prepare_inspection_plan.title')}</Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:prepare_inspection_plan.description_one')}</Text>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:prepare_inspection_plan.description_two')}</Text>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:prepare_inspection_plan.description_three')}</Text>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:prepare_inspection_plan.description_four')}</Text>
            </VerticalTimelineElement>
            <VerticalTimelineElement icon={<NumberIcon number={5} background="gray.500" />} position="left">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>
                {t('service_process_explained:order_mandatory_replacement_parts.title')}
              </Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:order_mandatory_replacement_parts.description_one')}</Text>
            </VerticalTimelineElement>
            <VerticalTimelineElement icon={<NumberIcon number={6} background="gray.500" />} position="left">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>
                {t('service_process_explained:customer_arrives_with_vehicle.title')}
              </Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:customer_arrives_with_vehicle.description_one')}</Text>
            </VerticalTimelineElement>
            <VerticalTimelineElement icon={<NumberIcon number={7} />} position="right">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>{t('service_process_explained:perform_service.title')}</Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:perform_service.description_one')}</Text>
              <Text style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: t('service_process_explained:perform_service.description_two') }} />
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:perform_service.description_three')}</Text>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:perform_service.description_four')}</Text>
            </VerticalTimelineElement>
            <VerticalTimelineElement icon={<NumberIcon number={8} />} position="right">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>{t('service_process_explained:service_entry.title')}</Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:service_entry.description_one')}</Text>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:service_entry.description_two')}</Text>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:service_entry.description_three')}</Text>
            </VerticalTimelineElement>
            <VerticalTimelineElement icon={<NumberIcon number={9} background="gray.500" />} position="left">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>{t('service_process_explained:return_vehicle.title')}</Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:return_vehicle.description_one')}</Text>
            </VerticalTimelineElement>
            <VerticalTimelineElement icon={<NumberIcon number={10} />} position="right">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>{t('service_process_explained:share_service_documents.title')}</Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:share_service_documents.description_one')}</Text>
              <Text style={{ fontSize: 14 }}>
                <Trans i18nKey="service_process_explained:share_service_documents.description_two" />
              </Text>
            </VerticalTimelineElement>
            <VerticalTimelineElement icon={<NumberIcon number={''} background="green.500" />} position="right">
              <Heading size={useBreakpointValue({ base: 'xxs', md: 'md' })}>{t('service_process_explained:service_completed.title')}</Heading>
              <Text style={{ fontSize: 14 }}>{t('service_process_explained:service_completed.description_one')}</Text>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </section>
        <Flex justifyContent={'flex-end'}>
          <Button fontSize="md" as={NavLink} to={'/app/w/dashboard'} variant="primary" mr={1}>
            {t('service_process_explained:cta')}
          </Button>
        </Flex>
      </ContentCard>
      <GetInTouchCard />
    </>
  );
};

export default ServiceProcessExplainedPage;
